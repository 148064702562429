<template>
    <div>
        <HeaderLayout :signbook-merchant-logo="signbookMerchantLogo"></HeaderLayout>
        <v-container v-show="$vuetify.breakpoint.mdAndDown">
            <v-row>
                <v-container class="order-details">
                    <v-row justify="start" align="left">
                        <v-col class="col-md-3" v-show="$vuetify.breakpoint.md">
                            <div class="pt-2 pb-2">
                                <div class="ml-1">
                                    Votre proposition de<br />
                                    <strong>FINANCEMENT</strong>
                                </div>
                                <img width="100" :src="floaLogoRvb" class="logo-floa" />
                                <v-badge id="loan-duration-badge"
                                         inline
                                         color="white"
                                         :value="`${ duration }X`"
                                         :content="`${ duration }X`">
                                </v-badge>
                            </div>
                        </v-col>
                        <v-col class="align-self-center col-md-5 col-sm-6 col-7 offset-md-0 offset-sm-1 pl-5">
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>Montant d'achat :</b>{{ amountAsked }} €</div>
                            </v-row>
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>Nombre de mensualités :</b> 9</div>
                            </v-row>
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>Montant 1ère mensualité :</b> {{ getMonthCost(1) }} €</div>
                            </v-row>
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>Mensualités suivantes :</b> {{ getMonthCost(2) }} €</div>
                            </v-row>
                        </v-col>
                        <v-col class="align-self-center col-md-4 col-sm-5 col-5">
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>TAEG fixe :</b> {{ taeg }} %</div>
                            </v-row>
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>Montant total dû :</b> {{ totalAmount }} €</div>
                            </v-row>
                            <v-row>
                                <div class="text-md-body-1 text-sm-body-2 text-caption"><b>Coût du crédit :</b> {{ loanCost }} €</div>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </v-container>
        <div v-if="signbookError == false">
            <v-container class="mt-5">
                <v-row class="mb-6">
                    <!-- Breadcrumb -->
                    <v-col cols="12" sm="12" md="12" lg="3" class="pt-0 mb-5">
                        <ul id="DesktopBreadcrumb" class="desktop-breadcrumb hidden">
                            <li class="desktop-breadcrumb__item item was-active">
                                <div class="breadcrumb__item__label">
                                    Vos données bancaires
                                </div>
                            </li>
                            <li class="desktop-breadcrumb__item item was-active">
                                <div class="breadcrumb__item__label">
                                    Votre identité
                                </div>
                            </li>
                            <li class="desktop-breadcrumb__item item was-active">
                                <div class="breadcrumb__item__label">
                                    Votre situation financière
                                </div>
                            </li>
                            <li class="desktop-breadcrumb__item item active">
                                <div class="breadcrumb__item__label">
                                    Signature de votre contrat
                                </div>
                            </li>
                            <div class="desktop-breadcrumb__bullet desktop-breadcrumb__progress"></div>
                        </ul>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" lg="3" class="order-summary pt-0" order-lg="1" v-show="$vuetify.breakpoint.lgAndUp">
                        <div id="summary-top" class="pt-2 pb-2">
                            Votre proposition de<br />
                            <strong>FINANCEMENT</strong><br />
                            <img width="100" :src="floaLogoRvb" style="display: inline-block; vertical-align: top;" />
                            <v-badge id="loan-duration-badge"
                                     inline
                                     color="white"
                                     :value="`${ duration }X`"
                                     :content="`${ duration }X`"></v-badge>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <b>Montant d'achat :</b><span class="display-right">{{ amountAsked }} €</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <b>Mensualités : </b><span class="display-right">9</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <b>1ère mensualité : </b><span class="display-right">{{ getMonthCost(1) }} €</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <b>Autres mensualités : </b><span class="display-right">{{ getMonthCost(2) }} €</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <b>TAEG fixe : </b><span class="display-right">{{ taeg }} %</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <b>Coût du crédit : </b><span class="display-right">{{ loanCost }} €</span>
                        </div>
                        <hr class="summary-hr" />
                        <div id="summary-bottom" class="pt-3 pb-3 loan-cost-line">
                            <b>Montant total dû : </b><span class="display-right floa-blue accent-4">{{ totalAmount }} €</span>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" class="d-lg-none" v-show="$vuetify.breakpoint.lgAndUp">
                        <hr class="mt-3 mb-3" />
                    </v-col>
                    <Contract
                        :gender="demand.customers[0].gender"
                        :lastName="demand.customers[0].lastName"
                        :is-loading="isLoading">
                    </Contract>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="5" md="5" lg="3" class="pt-0 mb-5" v-show="$vuetify.breakpoint.lgAndUp" />
                    <v-col cols="12" lg="6" class="pt-0 pl-0 pr-0">
                        <LegalNotice />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="height-center" v-else>
            <error></error>
        </div>
    </div>
</template>

<script>
import HeaderLayout from '../shared/HeaderLayout.vue';
import LegalNotice from '../shared/LegalNotice.vue';
import Contract from '../shared/Contract.vue';

    export default {
        name: 'SignCCL10X',
        components: {
            LegalNotice,
            HeaderLayout,
            Contract
        },
        props: {
            signbookError: Boolean,
            signbookUrl: String,
            signbookToken: String,
            signbookMerchantLogo: {
                type: String,
                default: ''
            },
            nthSignbook: Object,
            demand: Object,
            isLoading: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                floaLogo: require('../../../public/img/logo_floa.svg'),
                floaLogoRvb: require('../../../public/img/logo_floa_negative.svg'),
                showDetailsMobile: false,
            }
        },
        computed: {
            amountAsked: function () {
                return Number.parseFloat(this.demand.product.amountAsked).toFixed(2);
            },
            taeg: function () {
                return this.demand.product.taeg;
            },
            duration: function () {
                return this.demand.product.duration;
            },
            totalAmount: function () {
                return Number.parseFloat(this.demand.product.insuranceSubscribed ? this.demand.product.totalAmountDueWithInsurance : this.demand.product.totalAmountDue).toFixed(2);
            },
            insuranceCost: function () {
                return Number.parseFloat(this.demand.product.insuranceSubscribed ? (this.demand.product.insuranceMonthly * this.demand.product.duration) : 0).toFixed(2);
            },
            totalMonthly: function () {
                return this.demand.product.insuranceSubscribed ? this.demand.product.totalMonthlyWithInsurance : this.demand.product.totalMonthly;
            },
            loanCost: function () {
                return Number.parseFloat(this.demand.product.totalAmountDue - this.demand.product.amountAsked).toFixed(2);
            }
        },
        methods: {
            getMonthCost(monthNumber) {
                return Number.parseFloat(this.demand.paymentSchedules.paymentSchedules[monthNumber - 1].amount).toFixed(2)
            }
        }
    }
</script>
<style>
    #app > div {
        background-color: #F3F3F3 !important;
    }

    #app {
        font-family: 'Poppins'
    }

    #loan-duration-badge > span > span {
        margin-top: 14px;
        background-color: #FFF !important;
        border-color: #FFF !important;
        color: #009FFF !important;
        font-weight: bold !important;
        font-size: 0.9em;
        width: 45px;
        height: 21px;
    }

    #toolbar {
        text-align: center !important;
    }

    .toolbar-title {
        margin-left: 25px;
        font-size: 0.85em;
        color: #808080;
    }

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none !important;
    }

    #summary-top {
        background-color: #009FFF;
        color: #FFF;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .summary-middle {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #FFF;
        font-size: 0.8em;
        border-top: 1px solid #d3d3d3;
    }

    #summary-bottom {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #FFF;
        font-size: 0.8em;
        border-top: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .display-right {
        float: right;
    }

    .floa-color {
        color: #009FFF;
    }

    .floa-color-muted {
        color: #009FFF;
        opacity: 0.3;
    }

    .summary-hr {
        margin: 0 10px 0 15px;
    }

    .loan-cost-line {
        font-size: 1.05em !important;
        color: #000;
    }

    .recap-details-closed::before {
        content: url("../../../public/img/recap_plus.svg");
    }

    .recap-details-opened::before {
        content: url("../../../public/img/recap_minus.svg");
    }

    .recap-details::before {
        position: relative;
        left: -2px;
    }

    .recap-details {
        font-size: 12px;
    }

    .order-details {
        color: #fff;
        background-color: #009fff;
        border-radius: 10px 10px 10px 10px;
        font-size: 16px;
    }

    .order-details::after {
        content: "";
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #009fff;
        position: absolute;
        margin-top: 10px;
        left: 50%;
    }
</style>

<style scoped>
    @-webkit-keyframes rotating {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .desktop-breadcrumb {
        justify-self: end;
        display: block;
        justify-content: center;
    }

    .breadcrumb {
        padding: 0;
        margin: 0 15px 0 0;
        justify-self: end;
        position: static;
    }

    @media (min-width: 800px) {
        .breadcrumb {
            position: absolute;
            right: 15px;
        }
    }

    .breadcrumb__item {
        transition: background-color 200ms ease-in-out;
    }

    .desktop-breadcrumb {
        list-style-type: none;
        padding: 0;
        font-size: 0;
        position: sticky;
        top: 50px;
        font-family: 'Poppins', Helvetica, serif;
        font-weight: 500;
        z-index: 1;
        margin: auto;
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb {
            display: flex;
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb {
            display: flex;
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb {
            max-width: 400px;
        }
    }

    @media (min-width: 600px) and (max-width: 959px) {
        .desktop-breadcrumb {
            max-width: 600px;
        }
    }

    @media (min-width: 960px) and (max-width: 1263px) {
        .desktop-breadcrumb {
            max-width: 600px;
        }
    }

    .order-summary {
        font-size: 16px;
        font-family: Poppins, sans-serif;
    }

    .desktop-breadcrumb__item {
        font-size: 14px;
        position: relative;
        padding-right: 2rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #c0d9e5;
        text-align: right;
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__item {
            padding-right: 0;
            width: 150px;
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__item {
            padding-right: 0;
            width: 82.5px;
            font-size: 12px;
        }
    }

    .desktop-breadcrumb__item::after {
        display: block;
        position: absolute;
        background: #c0d9e5;
        border-radius: 50%;
        content: '';
        will-change: transform;
        z-index: 3;
        transition: transform 1000ms ease, background-color 1000ms ease, width 0.3s ease-in-out, height 0.3s ease-in-out right none, top 0.3s ease-in-out;
        width: 12px;
        height: 12px;
        right: -3px;
        top: 26px;
        transition-delay: all 0.3s ease, right none;
    }

    .desktop-breadcrumb__item::before {
        content: '';
        display: flex;
        justify-content: center;
        position: absolute;
        align-items: center;
        top: 30px;
        width: 6px;
        height: 0;
        right: 0;
        z-index: 2;
        background-color: #009fff;
        transition: height 1s ease-in-out;
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__item::before {
            width: 150px;
            left: 50%;
            right: 50%;
            top: 17px;
            transition: height none;
            transition: width 1s ease-in-out;
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__item::before {
            width: 80px;
            left: 50%;
            right: 50%;
            top: 17px;
            transition: height none;
            transition: width 1s ease-in-out;
        }
    }

    .desktop-breadcrumb__item.was-active, .desktop-breadcrumb__item.active {
        color: #009fff;
    }

    .desktop-breadcrumb__item.was-active::after, .desktop-breadcrumb__item.active::after {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
        width: 22px;
        right: -8px;
        top: 20px;
        background-color: #009fff;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__item.was-active::after, .desktop-breadcrumb__item.active::after {
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__item.was-active::after, .desktop-breadcrumb__item.active::after {
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__item.active::before {
            display: none;
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__item.active::before {
            display: none;
        }
    }

    .desktop-breadcrumb__item.was-active::before {
        height: 100%;
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__item.was-active::before {
            height: 6px;
        }
    }

    .floa-blue {
        color: #009fff;
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__item.was-active::before {
            height: 6px;
        }
    }

    .desktop-breadcrumb__item.was-active::after {
        background-image: url('../../../public/img/breadcrumbs-tick.svg');
    }

    .desktop-breadcrumb__item.active::after {
        background-image: url('../../../public/img/breadcrumbs-ring.svg');
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__item.not-active::after {
            left: 50%;
            top: 20px;
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__item.not-active::after {
            left: 50%;
            top: 20px;
            transform: translate(-50%, -50%);
        }
    }

    .desktop-breadcrumb__item.active, .desktop-breadcrumb__item.was-active {
        font-weight: bold;
    }

    .breadcrumb__item.-empty, .desktop-breadcrumb__item.-empty {
        display: none;
    }

    .desktop-breadcrumb__progress {
        position: absolute;
        right: 0;
        width: 6px;
        background-color: #c0d9e5;
        top: 28px;
        bottom: 24px;
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .desktop-breadcrumb__progress {
            width: 450px;
            height: 6px;
            top: 20px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .desktop-breadcrumb__progress {
            width: 247.5px;
            height: 6px;
            top: 20px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width: 1200px) {
        .desktop-breadcrumb {
            margin-right: 15px;
        }

        .breadcrumb__item__label {
            overflow-y: visible;
            max-height: 21px;
        }
    }

    @media (min-width: 600px) and (max-width: 1263px) {
        .breadcrumb__item__label {
            min-width: fit-content;
            position: relative;
            left: 75px;
            top: 40px;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }

    @media (min-width: 0px) and (max-width: 599px) {
        .breadcrumb__item__label {
            min-width: fit-content;
            position: relative;
            left: 41.25px;
            top: 43px;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
</style>

<style scoped>
    @import '../../assets/css/fonts.css';
</style>
